function resizeHeaderOnScroll() {
    const distanceY = window.pageYOffset || document.documentElement.scrollTop,
        shrinkOn = 200,
        headerEl = document.querySelector('[data-header]');

    if (distanceY > shrinkOn) {
        headerEl.classList.add('header--small');
    } else {
        headerEl.classList.remove('header--small');
    }
}

window.addEventListener('scroll', resizeHeaderOnScroll);
