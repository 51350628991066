(() => {

    const counterContainer = document.querySelector('#counter');

    if (counterContainer) {
        window.addEventListener('scroll', () => {

            const offsets = document.querySelector('#counter').getBoundingClientRect();
            const isVisible = (offsets.top >= 0) && (offsets.bottom <= window.innerHeight);

            if (isVisible) {
                const counters = document.querySelectorAll('.counter-value');
                const speed = 3000;

                counters.forEach((counter) => {
                    const animate = () => {
                        const value = +counter.getAttribute('data-count');
                        const data = +counter.innerText;

                        const time = value / speed;
                        if (data < value) {
                            counter.innerText = Math.ceil(data + time);
                            setTimeout(animate, 200);
                        } else {
                            counter.innerText = value;
                        }

                    };

                    animate();
                });
            }
        });
    }
})();
